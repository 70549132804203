// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-testingz-js": () => import("./../../../src/pages/testingz.js" /* webpackChunkName: "component---src-pages-testingz-js" */),
  "component---src-pages-tips-js": () => import("./../../../src/pages/tips.js" /* webpackChunkName: "component---src-pages-tips-js" */),
  "component---src-templates-tip-template-js": () => import("./../../../src/templates/tipTemplate.js" /* webpackChunkName: "component---src-templates-tip-template-js" */)
}

